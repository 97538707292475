import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import auth from "../service/auth";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import LogoGhl from "../service/logoGHL";
import axios from 'axios';
import '../App.css';
import TextareaAutosize from 'react-textarea-autosize';
import ClipLoader from 'react-spinners/ClipLoader';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Login = () => {
    const navigate = useNavigate();
    const [apiKey, setApiKey] = useState("");
    const [loading, setLoading] = useState(false);
    const [loader, setLoader] = useState(false); // Loader state
    const [accessToken, setAccessToken] = useState("");
    const [LocationId, setLocationId] = useState("");
    const [SiteId, setSiteId] = useState("");
    const [company_id, setCompnayId] = useState("");
    const [allLocations, setAllLocations] = useState([]);
    const [companyAccessToken, setCompanyAccessToken] = useState("");
    const [locationAccessToken, setLocationAccessToken] = useState("");
    const [accessType, setType] = useState(null);
    const [Connecting, setConnecting] = useState(false);
    const [ServerError, setServerError] = useState("");
    const [inputErrors, setInputErrors] = useState({
        LocationId: false,
        apiKey: false,
        SiteId: false,
        accessToken: false,
    });

    useEffect(() => {
        const url = window.location.href;
        const parsedUrl = new URL(url);
        const queryParams = new URLSearchParams(parsedUrl.search);
        let locations = queryParams.get('locations');
        if (locations && locations != null && locations.length != 0) {
            let type = queryParams.get('type');
            let access_token = queryParams.get('access_');
            let company_id = queryParams.get('c_id');
            locations = locations.replace("[", "").replace("]", "");
            locations = locations.includes(",") ? locations.split(",") : [locations];
            setCompnayId(company_id);
            setType(type);
            if (type == "company") {
                setCompanyAccessToken(access_token);
            } else {
                setLocationAccessToken(access_token);
            }
            setAllLocations(locations);
        }
    }, []);

    useEffect(() => {
        resize();
    }, [apiKey, accessToken]);

    const resize = () => {
        const textarea = document.getElementById('autosize');
        const textarea2 = document.getElementById('accessToken');
        if (textarea) {
            textarea.style.height = 'auto';
            textarea.style.height = `${textarea.scrollHeight - 2}px`;
        }
        if (textarea2) {
            textarea2.style.height = 'auto';
            textarea2.style.height = `${textarea2.scrollHeight - 2}px`;
        }
    };

    const handleInputChange = (e, inputName) => {
        const value = e.target.value;
        setServerError("");
        const newInputErrors = { ...inputErrors, [inputName]: !value };
        setInputErrors(newInputErrors);
        switch (inputName) {
            case "LocationId":
                setLocationId(value);
                break;
            case "apiKey":
                setApiKey(value);
                break;
            case "SiteId":
                setSiteId(value);
                break;
            case "accessToken":
                setAccessToken(value);
                break;
            default:
                break;
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!SiteId || !accessToken) {
            setInputErrors({
                SiteId: !SiteId,
                accessToken: !accessToken,
            });
            return;
        }
        if (allLocations.length == 0) {
            alert("No location found")
            return
        }
        try {
            setLoader(true); // Show loader
            setConnecting(true);
            var postData = {
                locationAPIKeyToken: apiKey,
                location_id: LocationId,
                site_id: SiteId,
                access_token: accessToken,
                all_locations: allLocations,
                // c_a_token: companyAccessToken,
                c_id: company_id,
                type: accessType
            };
            if (accessType == "company") {
                postData['c_a_token'] = companyAccessToken;
            } else {
                postData['l_a_token'] = locationAccessToken;
            }
            console.log('postData', postData);
            const responseData = await axios.post(`https://360fivedev.com/api/auth-connection`, postData);
            // const responseData = await axios.post(`${process.env.REACT_APP_URL}/api/auth-connection`, postData);
            if (responseData && responseData.data && responseData.data.status) {
                console.log(allLocations[0], "LocationId")
                console.log(process.env.REACT_APP_APP_ID, "process.env.REACT_APP_APP_ID")
                const redirectUrl = `https://app.gohighlevel.com/v2/location/${allLocations[0]}/integration/66b9d20a20a2bb50742fd6f0`;
                //   const redirectUrl = `https://app.gohighlevel.com/v2/location/${allLocations[0]}/integration/${process.env.REACT_APP_APP_ID}`;
                console.log(redirectUrl, "redirectUrl")
                window.location.href = redirectUrl;
            } else {
                setLoader(false)
                toast.error('Invalid Site Id or Access Token');
            }
        } catch (error) {
            console.log('error', error);
            setConnecting(false);
            setLoader(false)
        }
    };

    return (
        <>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                closeOnClick
                pauseOnHover
            />
            <div className="container-fluid">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="conntection-section">
                                <div className="brandLogo">
                                    <LogoGhl />
                                </div>
                                <div className="auth_Configuration">
                                    <h2>Configuration</h2>
                                    <label className="form-label">
                                        <span>
                                            Site Id
                                            <OverlayTrigger
                                                placement="top"
                                                overlay={<Tooltip id="tooltip-right">linked2checkout Site ID</Tooltip>}
                                            >
                                                <i className="bi bi-info-circle-fill"></i>
                                            </OverlayTrigger>
                                        </span>
                                        <a target="_blank" rel="noreferrer" href="https://linked2checkout.com/docs/3.0/introduction">How to get</a>
                                    </label>
                                    <TextareaAutosize
                                        type="text"
                                        id="SiteId"
                                        className={inputErrors.SiteId ? "autosize textarea-no-scrollbar errorColor" : "autosize textarea-no-scrollbar"}
                                        value={SiteId}
                                        onChange={(e) => handleInputChange(e, "SiteId")}
                                        placeholder="Enter your Site Id"
                                    />

                                    <label className="form-label">
                                        <span>
                                            Access Token
                                            <OverlayTrigger
                                                placement="top"
                                                overlay={<Tooltip id="tooltip-right">linked2checkout Access Token</Tooltip>}
                                            ><i className="bi bi-info-circle-fill"></i>
                                            </OverlayTrigger>
                                        </span>
                                        <a target="_blank" rel="noreferrer" className="aTagLink" href="https://linked2checkout.com/docs/3.0/introduction">How to get</a>
                                    </label>
                                    <TextareaAutosize
                                        type="text"
                                        id="accessToken"
                                        className={inputErrors.accessToken ? "autosize textarea-no-scrollbar errorColor " : "autosize textarea-no-scrollbar"}
                                        rows="1"
                                        value={accessToken}
                                        onChange={(e) => handleInputChange(e, "accessToken")}
                                        placeholder="Enter your access token"
                                    />
                                    <div className="create-connection">
                                        <button
                                            className="filter mt-3 m-auto"
                                            onClick={handleSubmit}
                                        >
                                            {loading ? "Connecting..." : "Submit"}{" "}
                                        </button>

                                    </div>
                                    {loader && (
                                        <div className="loader-container">
                                            <ClipLoader color="#4A90E2" loading={loader} size={50} />
                                        </div>
                                    )}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}

export default Login;