import React, { useEffect, useState } from "react";
import axios from "axios";

const PaymentIframe = () => {
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    window.parent.postMessage(
      JSON.stringify({
        type: 'custom_provider_ready',
        loaded: true,
      }),
      '*'
    )
    const handleMessages = async (event) => {
      console.log("Received payment data:", event.data);
      console.log("Received payment data parse:", JSON.parse(event.data));

      const payload = JSON.parse(event.data);
      if (payload.invoiceId && payload.locationId) {
        setLoading(true)
        const invoiceId = payload.invoiceId;
        const locationId = payload.locationId;
        const url = `${process.env.REACT_APP_URL}/oAuth/getInvoiceData?invoiceId=${invoiceId}&locationId=${locationId}`;
        axios.get(url)
          .then((response) => {
            console.log('Data successfully get:', response.data);
            // Redirect using the link from the API response
            const redirectionLink = response.data.data.link;
            if (redirectionLink) {
              window.location.href = redirectionLink;
            } else {
              console.error('No redirection link provided in the response');
            }
          })
          .catch((error) => {
            console.error('Error getting data:', error);
          })
          .finally(() => {
            setLoading(false);
          });
      } else if (payload.transactionId && payload.locationId) {
        setLoading(true)
        await postTransactionData(payload);
      } else {
        console.error('Invalid data received:', event.data);
      }

    };
    window.addEventListener('message', handleMessages);

  }, []);



  const postTransactionData = async (data) => {
    try {
      setLoading(true);
      const response = await axios.post(`${process.env.REACT_APP_URL}/oAuth/paymentLink`, data);
      console.log("Transaction data successfully posted:", response.data);

      if (response.data.status) {
        const redirectionLink = response.data.data.link;
        console.log(redirectionLink, "redirectionLink")
        if (redirectionLink) {
          window.location.href = redirectionLink;
        } else {
          console.error('No redirection link provided in the response');
        }
      } else {
        console.error("Payment link creation failed:", response.data.message);
      }
    } catch (error) {
      if (error.response) {
        console.error("Error response from server:", error.response.data);
      } else {
        console.error("Error posting transaction data:", error.message);
      }
    } finally {
      setLoading(false);
    }
  };



  return (
    <div>
      <h2>{loading ? "Loading..." : "Payment integration Connecting:"}</h2>
    </div>
  );
};

export default PaymentIframe;
